<template>
  <div class="row none-spacing">
    <div class="imgBox  col-lg-7 col-md-7 col-sm-7 none-spacing" :style="{'height':heightValue+'px'}">
      <img :src="src" alt="" />
    </div>
    <div class="txtArea col-lg-5 col-md-5 col-sm-5" :style="{'height':heightValue+'px'}">
      <div>{{ title }}</div>
      <div>
        {{ txtarea }}
      </div>
      <div class="tryOutBtn" v-if="btnType=='0'" @click="link(routerLink)">{{copyWriting}}<span>></span></div>
      <div class="tryOutBtn" v-if="btnType=='1'" @click="reg">立即试用</div>
    </div>
  </div>
</template>
<script>
import {toRegister, linkTo } from "@/utils/common";
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "伟班",
    },
    txtarea: {
      type: String,
    },
    btnType: {
      type: String,
      default: "0"
    },
    routerLink: {
      type: String,
      default: "0"
    },
    copyWriting:{
      type: String,
      default: "查看详情"
    },
    heightValue:{
      type: Number,
      default: 450
    }
  },
  data() {
    return {};
  },
  methods: {
    reg(name_) {
      toRegister();
    },
    link(prefix){
      linkTo(prefix,'');
    }
  }
};
</script>

<style scoped lang="less">
.imgBox{
    width: 48%;
    padding-right: 2%!important;
    img {
        width: 100%;
        height: 100%;
    }
}

.txtArea {
    width: 50%;
    padding-left: 6%;
    box-sizing: border-box;
    &> div:nth-of-type(1) {
    font-size: 30px;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 30px;
    color: #2a2727;
    text-align: left;
    }
    & > div:nth-of-type(2) {
        width: 480px;
        height: 195px;
        font-size: 20px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 33px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }
    .tryOutBtn {
        span{
          margin-left: 3px;
        }
        height: 22px;
        font-size: 22px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #1584ff;
        line-height: 22px;
        position: absolute;
        bottom: 20px;
        cursor: pointer;
    }
}

.row{
  height: 481px;
}
</style>
