<template>
  <div id="home-page">
    <!-- <top-nav /> -->
    <top-nav-new />
    <!-- src="@/assets/product/veiban-app/video2.mp4" -->
    <!-- vedio PC -->
    <!-- src="https://ucloudimage.epipe.cn/%E5%B0%81%E9%9D%A2%E6%97%A0%E5%AD%97%E7%89%88.mp4" -->
    <div class="video-one-main d-none d-lg-block" style="position: relative">
      <div
        style="
          width: 100%;
          height: 1074.38px;
          left: 0px;
          background: black;
          position: absolute;
          opacity: 0.5;
        "
      ></div>
      <video
        autoplay="autoplay"
        src="@/assets/newSite/bg.mp4"
        loop="loop"
        muted="muted"
        id="myVideo"
        style="width: 1910px; height: 1074.38px; left: 0px; top: -56.6875px"
      ></video>

      <div
        class="lunbo"
        style="
          width: 1000px;
          height: 600px;
          position: absolute; 
          bottom: 100px;
          left: 200px;
          overflow: hidden;
        "
      >
        <swiper
          class="Mswiper"
          style="height:300px;"
          :options="swiperOptionOffice"
        >
          <swiper-slide>
            <div
              style="
                color: white;
                font-size: 60px;
                font-weight: 100;
                text-align: left; 
              "
            >
              <div>智能办公，化繁为简，</div>
              <div>让工作更有趣，更高效。</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              style="
                color: white;
                font-size: 60px;
                font-weight: 100;
                text-align: left;  
              "
            >
              <div>智慧园区，打造智能，</div>
              <div>绿色、便捷的园区环境。</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              style="
                color: white;
                font-size: 60px;
                font-weight: 100;
                text-align: left; 
              "
            >
              <div>智能制造，以智能为导向，</div>
              <div>创新为动力，助力工业新时代。</div>
            </div>
          </swiper-slide>

          <div
            class="swiper-pagination-top"
            style="text-align: left;color: white;"
            slot="pagination"
          > </div>
        </swiper>
      </div>
    </div>
    <!-- 手机端显示 -->
    <div class="video-one-main d-block d-lg-none">
      <swiper class="MswiperMobile" :options="swiperOptionBanner">
        <swiper-slide>
          <div style="text-align: left">
            <img
              src="@/assets/newSite/banner/s3.png"
              alt=""
              style="width: 100%"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div style="text-align: left">
            <img
              src="@/assets/newSite/banner/s1.png"
              alt=""
              style="width: 100%"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div style="text-align: left">
            <img
              src="@/assets/newSite/banner/s2.png"
              alt=""
              style="width: 100%"
            />
          </div>
        </swiper-slide>
        <div
          class="swiper-pagination-w"
          style="text-align: left"
          slot="pagination"
        ></div>
      </swiper>

      <!-- <video
        autoplay="autoplay"
        src="@/assets/newSite/mobile.mp4"
        loop="loop"
        muted
        controls
        id="myVideo"
        type="video/mp4"
        style="position: absolute; width:100%;height:350px; left: 0; top: 0"
      ></video> -->
    </div>
    <!-- 工业互联网领域软件提供商 -->

    <!-- <section class="container">
      <div> 
        <span @click="shows = !shows">123{{ shows }}</span>
        <transition name="fade">
            <p v-if="shows">hello,看我渐渐地消失。</p>
        </transition>
      </div>
    </section> -->

    <section class="pc-soft-provide d-none d-lg-block">
      <div class="container text-center">
        <h3 class="pro-tit">工业互联网领域软件提供商</h3>
        <h5 class="pro-tits">软件定义制造，智能引领未来</h5>
        <div class="row">
          <div class="col">
            <div class="items">
              <h2><img src="@/assets/newSite/a.png" alt="" /></h2>
              <div style="margin-bottom: 1rem; text-align: left">
                <h2 class="datas">
                  <countTo
                    :startVal="startVal"
                    :endVal="endVal"
                    :duration="4000"
                  ></countTo
                  >年
                </h2>
                <h4 class="tip">专注工业互联网软件领域</h4>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="items">
              <div><img src="@/assets/newSite/b.png" alt="" /></div>
              <div style="margin-bottom: 1rem; text-align: left">
                <h2 class="datas">
                  <countTo
                    :startVal="startCompanys"
                    :endVal="endCompanys"
                    separator=""
                    :duration="4000"
                  ></countTo
                  >+
                </h2>
                <h4 class="tip">致力于服务中小微企业</h4>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="items">
              <div><img src="@/assets/newSite/c.png" alt="" /></div>
              <div style="margin-bottom: 1rem; text-align: left">
                <h2 class="datas">
                  <countTo
                    :startVal="startUser"
                    :endVal="endUser"
                    separator=""
                    :duration="4000"
                  ></countTo
                  >+
                </h2>
                <h4 class="tip">致力于产品用户数量</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mobile-soft-provide d-block d-lg-none">
      <div class="container text-center">
        <h3 class="pro-tit">工业互联网领域软件提供商</h3>
        <h5 class="pro-tits">软件定义制造，智能引领未来</h5>
        <div class="wrap">
          <div class="row">
            <div class="col">
              <div class="items">
                <div>
                  <img
                    src="@/assets/newSite/a.png"
                    class="avatar avatar-lg mr-3"
                  />
                </div>
                <div style="text-align: left">
                  <h2 class="datas">
                    <countTo
                      :startVal="startVal"
                      :endVal="endVal"
                      :duration="3000"
                    ></countTo
                    >年
                  </h2>
                  <h5 class="tip">专注工业互联网软件领域</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="items">
                <div><img src="@/assets/newSite/b.png" alt="" /></div>
                <div style="text-align: left">
                  <h2 class="datas">
                    <countTo
                      :startVal="startCompanys"
                      :endVal="endCompanys"
                      separator=""
                      :duration="3000"
                    ></countTo
                    >+
                  </h2>
                  <h5 class="tip">致力于服务中小微企业</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="items">
                <div><img src="@/assets/newSite/c.png" alt="" /></div>
                <div style="text-align: left">
                  <h2 class="datas">
                    <countTo
                      :startVal="startUser"
                      :endVal="endUser"
                      separator=""
                      :duration="3000"
                    ></countTo
                    >+
                  </h2>
                  <h5 class="tip">致力于产品用户数量</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 我们的产品 -->
    <section class="pc-product d-none d-lg-block">
      <div class="container text-center">
        <h3 class="pro-tit">我们的产品</h3>
        <div class="row align-items-start">
          <div
            :class="['col', idx == index ? 'acticv' : '']"
            @click="handleSwitch(index)"
            v-for="(item, index) in tabs"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="pro-wrap">
        <div
          class="container animate__animated animate__fadeInLeftBig"
          v-show="idx == 0"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6>智能制造</h6>
              <p class="p-content">
                提高生产效率：通过自动化的生产线和机器人，提高了生产效率，减少了人工干预，降低了生产成本。
                增加生产精度：利用先进的技术和设备，能够实现高精度的制造和检测，达到更高的产品质量。
                提高生产安全性：自动识别和预防潜在的安全风险，降低事故发生的可能性，保障工人的生命安全。
                实现个性化定制：根据客户需求，实现个性化的产品设计和制造，满足客户的多样化需求。
                降低能源消耗：通过能源管理系统和节能设备，降低能源消耗，实现绿色、环保的制造方式。
              </p>
              <div class="btns" style="margin-bottom: 40px">
                <el-tag type="info">优制云MES</el-tag>
                <el-tag type="info" style="margin: 0 10px">优制云WMS</el-tag>
                <el-tag type="info">优制云CRM</el-tag>
              </div>
              <div>
                <button
                  @click="
                    () => {
                      this.$router.push('/smartMade');
                    }
                  "
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img src="@/assets/newSite/home/s3.png" />
            </div>
          </div>
        </div>
        <div
          class="container animate__animated animate__fadeInLeftBig"
          v-show="idx == 1"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6>智能办公</h6>
              <p class="p-content">
                智能化：借助先进的技术和设备，智能办公能够实现智能化决策、智能化处理、智能化控制等，提高工作效率和质量。
                高效性：能够减少繁琐的手动操作，简化工作流程，提高工作效率。
                协同性：能够实现团队协作的高效协同，提高团队协作效率。
                安全性：具备较高的安全性，能够保护企业和员工的隐私和数据安全。
                移动性：支持移动办公，IM即时聊天，员工可以随时随地进行办公，提高工作效率和灵活性。
                云端化：基于云计算技术实现，能够实现数据的云端存储、备份和恢复等功能，提高数据的安全性和可靠性。
                数据分析：能够对各种数据进行分析和挖掘，为企业提供更全面的数据支持和决策参考。
              </p>
              <div class="btns" style="margin-bottom: 40px">
                <el-tag type="info" style="">伟班（OA）</el-tag>
              </div>
              <div>
                <button
                  @click="
                    () => {
                      this.$router.push('/smartOffice');
                    }
                  "
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img src="@/assets/newSite/home/s2.png" />
            </div>
          </div>
        </div>
        <div
          class="container animate__animated animate__fadeInRightBig"
          v-show="idx == 2"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6>智慧园区</h6>
              <p class="p-content">
                数据可视化及应用：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                能耗管理：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行，访客通行权限…
                安全管理：视频上云助力园区远程巡视，降本增效；制定园区安全检查标准，通过视频巡更+图片巡更方式进行园区日常巡逻，提高管理效率…
                设备运维：融合AIoT技术，实现对设备资产的全生命周期管理，变配电房、给排水房等机电设备智能化实时监测，降低安全隐患…
                服务体验：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                集成能力：打通园区运营系统、工单等系统，实现智能化数据与业务系统的流程自动化、全场景闭环，可将智能化应用集成至现有员工app…
              </p>
              <div class="btns" style="margin-bottom: 40px">
                <el-tag type="info" style="">智慧园区</el-tag>
              </div>
              <div>
                <button
                  @click="
                    () => {
                      this.$router.push('/smartPark');
                    }
                  "
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img src="@/assets/newSite/home/s1.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mobile-product d-block d-lg-none">
      <div class="container text-center">
        <h3 class="pro-tit">我们的产品</h3>
        <div class="row align-items-start">
          <div
            :class="['col', idx == index ? 'acticv' : '']"
            @click="handleSwitch(index)"
            v-for="(item, index) in tabs"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="pro-wrap">
        <div
          class="container animate__animated animate__fadeInLeftBig"
          v-show="idx == 0"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6 style="font-weight: bold; margin-bottom: 0.5rem">智能制造</h6>
              <p class="p-content">
                提高生产效率：智能制造通过自动化的生产线和机器人，提高了生产效率，减少了人工干预，降低了生产成本。
                增加生产精度：智能制造利用先进的技术和设备，能够实现高精度的制造和检测，达到更高的产品质量。
                提高生产安全性：智能制造可以自动识别和预防潜在的安全风险，降低事故发生的可能性，保障工人的生命安全。
                实现个性化定制：智能制造可以根据客户需求，实现个性化的产品设计和制造，满足客户的多样化需求。
                降低能源消耗：智能制造可以通过能源管理系统和节能设备，降低能源消耗，实现绿色、环保的制造方式。
              </p>
              <div class="btns">
                <el-tag type="info">优制云MES</el-tag>
                <el-tag type="info" style="margin: 0 10px">优制云WMS</el-tag>
                <el-tag type="info">优制云CRM</el-tag>
              </div>
              <div>
                <button
                  @click="
                    () => {
                      this.$router.push('/smartMade');
                    }
                  "
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img src="@/assets/newSite/home/s3.png" />
            </div>
          </div>
        </div>
        <div
          class="container animate__animated animate__fadeInLeftBig"
          v-show="idx == 1"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6 style="font-weight: bold; margin-bottom: 0.5rem">智能办公</h6>
              <p class="p-content">
                智能化：借助先进的技术和设备，智能办公能够实现智能化决策、智能化处理、智能化控制等，提高工作效率和质量。
                高效性：能够减少繁琐的手动操作，简化工作流程，提高工作效率。
                协同性：能够实现团队协作的高效协同，提高团队协作效率。
                安全性：具备较高的安全性，能够保护企业和员工的隐私和数据安全。
                移动性：支持移动办公，IM即时聊天，员工可以随时随地进行办公，提高工作效率和灵活性。
                云端化：基于云计算技术实现，能够实现数据的云端存储、备份和恢复等功能，提高数据的安全性和可靠性。
                数据分析：能够对各种数据进行分析和挖掘，为企业提供更全面的数据支持和决策参考。
              </p>
              <div class="btns" style="">
                <el-tag type="info" style="">伟班（OA）</el-tag>
              </div>
              <div>
                <button
                  @click="
                    () => {
                      this.$router.push('/smartOffice');
                    }
                  "
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img src="@/assets/newSite/home/s2.png" />
            </div>
          </div>
        </div>
        <div
          class="container animate__animated animate__fadeInRightBig"
          v-show="idx == 2"
        >
          <div class="content">
            <div style="flex: 60%">
              <h6 style="font-weight: bold; margin-bottom: 0.5rem">智慧园区</h6>
              <p class="p-content">
                数据可视化及应用：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                能耗管理：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行，访客通行权限…
                安全管理：视频上云助力园区远程巡视，降本增效；制定园区安全检查标准，通过视频巡更+图片巡更方式进行园区日常巡逻，提高管理效率…
                设备运维：融合AIoT技术，实现对设备资产的全生命周期管理，变配电房、给排水房等机电设备智能化实时监测，降低安全隐患…
                服务体验：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                集成能力：打通园区运营系统、工单等系统，实现智能化数据与业务系统的流程自动化、全场景闭环，可将智能化应用集成至现有员工app…
              </p>
              <div class="btns">
                <el-tag type="info" style="">智慧园区</el-tag>
              </div>
              <div>
                <button
                  @click="
                    () => {
                      this.$router.push('/smartPark');
                    }
                  "
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  立即体验
                </button>
              </div>
            </div>
            <div style="flex: 40%; text-align: center">
              <img src="@/assets/newSite/home/s3.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 解决方案 -->
    <section class="pc-resolve d-none d-lg-block">
      <div class="container text-center">
        <h3 class="pro-tit">解决方案</h3>
        <h5 class="pro-tits">致力于为用户提供全行业解决方案</h5>
        <div class="row bg1 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h4>智能办公解决方案</h4>
            <p>
              致力于为广大中小微企业提供综合的、标准的、一体化的、全方位的数字化解决方案
              以“互联、共享、智能”理念为核心,助力企业数字化转型,成就智慧企业。
            </p>
            <button
              @click="
                () => {
                  this.$router.push('/officeSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
        <div class="row bg2 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h4>智能制造解决方案</h4>
            <p>
              帮助企业实现智能化、高效化的生产管理，提高工作效率和业务水平，同时也可以降低成本、减少风险，为企业的发展提供有力支持。
            </p>
            <button
              @click="
                () => {
                  this.$router.push('/madeSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
        <div class="row bg3 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h4>智慧园区解决方案</h4>
            <p>
              数智赋能让园区更智能、更便捷、更高效、面向园区运营方、管理机构、企业及个人提供全栈式数智服务，融合物联网、大数据、AI、数字孪生等领域的产品能力，打造园区全联接、全融合、全智能，助力园区及企业实现基础设施智能化、运营管理数字化、产业发展生态化的智慧化目标。
            </p>
            <button
              @click="
                () => {
                  this.$router.push('/parkSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="mobile-resolve d-block d-lg-none">
      <div class="container text-center">
        <h3 class="pro-tit">解决方案</h3>
        <h5 class="pro-tits">致力于为用户提供全行业解决方案</h5>
        <div class="row bg1 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h6>智能办公解决方案</h6>
            <!-- <h6>解决方案</h6> -->
            <button
              @click="
                () => {
                  this.$router.push('/officeSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
        <div class="row bg2 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h6>智能制造解决方案</h6>
            <button
              @click="
                () => {
                  this.$router.push('/madeSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
        <div class="row bg3 animate__animated animate__fadeInUp">
          <div class="main-content">
            <h6>智慧园区解决方案</h6>
            <button
              @click="
                () => {
                  this.$router.push('/parkSolution');
                }
              "
              type="button"
              class="btn btn-primary btn-sm"
            >
              了解更多
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="pc-news d-none d-lg-block">
      <div class="container text-center">
        <h3 class="pro-tit">新闻资讯</h3>
        <div class="news-swiper">
          <div class="controls">
            <div>
              <div
                @click="handleSelectTpye(idxs)"
                v-for="(itm, idxs) in types"
                :key="idxs"
                :class="['opertaion', sel == idxs ? 'active' : '']"
              >
                <span>{{ itm }}</span>
                <span
                  style="margin-left: 10px"
                  class="el-icon-arrow-right"
                ></span>
              </div>
            </div>
            <div class="preAndNext" v-if="swipList.length">
              <div
                :class="['before', crrentItem == 1 ? 'dis' : 'activ']"
                @click="lunboleft"
              >
                <span class="el-icon-arrow-left"></span>
              </div>
              <div class="nums">{{ crrentItem }}/{{ totoleNum }}</div>
              <div
                :class="['after', crrentItem == totoleNum ? 'dis' : 'activ']"
                @click="lunboright"
              >
                <span class="el-icon-arrow-right"></span>
              </div>
            </div>
          </div>
          <swiper class="swiper defistyle" :options="swiperOption" v-if="swipList.length">
            <swiper-slide
              class="defiwidth"
              style=""
              v-for="(item, index) in swipList"
              :key="index"
            >
              <div @click="handleDetail(item.id, 'home')">
                <img
                  v-if="item.contentImgUrl"
                  :src="fixedPath + item.contentImgUrl"
                />
                <img v-else src="@/assets/newSite/news/no.png" alt="" />
                <div
                  style="
                    font-size: 14px;
                    color: #333333;
                    padding: 10px 10px 0 10px;
                    text-align: left;
                    font-weight: 700;
                  "
                >
                  {{ item.contentDatetime }}
                </div>
                <div
                  style="
                    text-align: left;
                    padding: 0.8rem;
                    font-size: 14px;
                    height: 120px;
                    width: 220px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 5;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    color: #333333;
                  "
                >
                  {{ item.contentDescription }}
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination">1</div>
            <div class="swiper-button-prev" slot="button-prev">2</div>
            <div class="swiper-button-next" slot="button-next">3</div>
          </swiper>
          <div style="padding-top: 20px;width: 100%;" v-if="!swipList.length">暂无新闻数据...</div>
        </div>
      </div>
    </section>

    <section class="mobile-news d-block d-lg-none">
      <div class="container text-center">
        <h3 class="pro-tit">新闻咨询</h3>

        <div class="controls">
          <div class="wrap">
            <div
              @click="handleSelectTpye(idxs)"
              v-for="(itm, idxs) in types"
              :key="idxs"
              :class="['opertaion', sel == idxs ? 'active' : '']"
            >
              <span>{{ itm }}</span>
            </div>
          </div>
        </div>

        <div class="news-swiper">
          <swiper class="swiper m_swiper" :options="swiperOption2" v-if="swipList.length">
            <swiper-slide v-for="(item, index) in swipList" :key="index">
              <div @click="handleDetail(item.id, 'home')">
                <img
                  v-if="item.contentImgUrl"
                  :src="fixedPath + item.contentImgUrl"
                  alt=""
                />
                <img v-else src="@/assets/newSite/news/no.png" alt="" />
                <div
                  style="
                    font-size: 14px;
                    color: #333333;
                    padding: 10px 10px 0 10px;
                    text-align: left;
                  "
                >
                  {{ item.contentDatetime }}
                </div>
                <div
                  style="
                    text-align: left;
                    padding: 0.5rem;
                    font-size: 14px;
                    height: 90px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 4;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    color: #333333;
                  "
                >
                  {{ item.contentDescription }}
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination-m" slot="pagination"></div>
            <div class="swiper-button-prev-m" slot="button-prev"></div>
            <div class="swiper-button-next-m" slot="button-next"></div>
          </swiper>
          <div style="padding-top: 20px;"  v-if="!swipList.length">暂无新闻数据...</div>
          <div class="preAndNext" v-if="swipList.length">
            <div
              :class="['before', crrentItem == 1 ? 'dis' : 'activ']"
              @click="lunboleftM"
            >
              <span class="el-icon-arrow-left"></span>
            </div>
            <div class="nums">{{ crrentItem }}/{{ totoleNumMobile }}</div>
            <div
              :class="[
                'after',
                crrentItem == totoleNumMobile ? 'dis' : 'activ',
              ]"
              @click="lunborightM"
            >
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <h1 class="animate__animated animate__bounce">An animated element</h1> -->

    <!-- <Banner   :style="{ 'margin-top': marginTop + 'px' }" /> -->

    <!-- <section class="home-introduce"> -->
 

 

 

    <NewFooter />

    <right-nav-new class="d-none d-lg-block" />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>

<script>
import {
  Companydynamics,
  Industrydynamics,
  Newsdetail,
  Dynamicnavigationbar,
  Newslist,
} from "@/api/fetch";

// import Banner from "@/components/Banner/index.vue";
import advSpace from "@/components/AdvertisingSpace.vue";
import countTo from "vue-count-to";
import Qs from "qs";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay, EffectFade ,Navigation,Pagination} from "swiper";
SwiperCore.use([Autoplay, EffectFade,Navigation,Pagination]);
// import 'animate.css';
// import { WOW } from 'wow.js'

export default {
  name: "Home",
  data() {
    return {
      startVal: 0,
      endVal: 5,

      startCompanys: 0,
      endCompanys: 2000,

      startUser: 0,
      endUser: 100000,

      fixedPath: this.$BaseUrl,
      shows: true,
      show: false,
      showBoard: false,
      dynamic: [],
      Journalism: [],
      tabs: ["智能制造", "智能办公", "智慧园区"],
      idx: 0,
      types: ["公司动态", "行业资讯"],
      sel: 0,
      totoleNum: 0,
      totoleNums: 0,
      totoleNumMobile: 0,
      swipList: [
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news2.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news2.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news2.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
        {
          img: require("@/assets/newSite/news1.png"),
          time: "2023.7.05",
          cont: "中国工业互联网发展加速，在农业、制造业和城市化等领域实现突破。技术的不断创新和应用…",
        },
      ],
      crrentItem: 1,

      swiperOptionBanner: {
        // effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        loop: true,
        // spaceBetween: 30,
        // centeredSlides: false,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination-w",
          clickable: true,
        },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },

      swiperOptionOffice: {
        // effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        loop: true,
        spaceBetween: 30,
        centeredSlides: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination-top",
          clickable: true,
        },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },

      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 40,
        loop: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChange: (e) => {
            this.totoleNum =
              this.swipList.length - e.passedParams.slidesPerView + 1;
            this.crrentItem = e.activeIndex + 1;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      swiperOption2: {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,
        pagination: {
          el: ".swiper-pagination-m",
          clickable: true,
        },
        on: {
          slideChange: (e) => {
            this.totoleNumMobile =
              this.swipList.length - e.passedParams.slidesPerView + 1;
            this.crrentItem = e.activeIndex + 1;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next-m",
          prevEl: ".swiper-button-prev-m",
        },
      },

      swiperOptionsPc: {
        autoplay: {
          delay: 5000,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        on: {
          slideChangeTransitionStart: function () {
            let e = $(".banner-pc-nav").find("li")[this.activeIndex];
            $(e).siblings().removeClass("active");
            $(e).addClass("active");
          },
        },
      },
      swiperOptionsMobile: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        height: 100,
        speed: 600,
      },
      countTo1: false,
      countTo2: false,
      countTo3: false,
      countTo4: false,
      marginTop: 70,
      activeNames: ["1"],
      windowHeight: document.body.clientHeight,
      windowWidth: document.body.clientWidth + "px" || "1920px",
      hoverKey: -1,
      isMobile: false,
      ids: "1704670299637411842", //公司动态 //行业咨询 1704670418562707457
    };
  },
  components: {
    // Banner,
    countTo,
    advSpace,
    Swiper,
    SwiperSlide,
  },
  watch: {
    // 监听页面高度
    windowHeight(val) {
      console.log("实时屏幕高度：", val, this.windowHeight);
    },
    // 监听页面宽度
    windowWidth(val) {
      console.log("实时屏幕宽度：", val, this.windowHeight);
    },
  },
  created() {
    this.getNews();
  },
  methods: {
    handleDetail(id, origin) {
      this.$router.push({
        path: "/detail",
        query: {
          id,
          origin,
        },
      });
    },
    //获取新闻
    getNews() {
      Newslist(
        Qs.stringify({ categoryId: this.ids, pageSize: 10, pageNo: 1 })
      ).then((res) => {
        if (res.data.rows) {
          this.totoleNum =
            res.data.rows.length - this.swiperOption.slidesPerView + 1;
          this.totoleNumMobile = res.data.total;
          console.log(this.totoleNum, 777);
          this.swipList = res.data.rows;
        }
        // console.log(res, 999);
      });
    },

    lunboleft() {
      // console.log(document,9999)
      document
        .querySelector(".swiper")
        .querySelector(".swiper-button-prev")
        .click();
      // console.log(123);
    },
    lunboright() {
      // console.log(document,9999)
      document
        .querySelector(".swiper")
        .querySelector(".swiper-button-next")
        .click();
      // console.log(123);
    },
    lunboleftM() {
      // console.log(document,9999)
      document
        .querySelector(".m_swiper")
        .querySelector(".swiper-button-prev-m")
        .click();
      console.log(456);
    },
    lunborightM() {
      console.log(
        document
          .querySelector(".m_swiper")
          .querySelector(".swiper-button-next-m"),
        111
      );
      document
        .querySelector(".m_swiper")
        .querySelector(".swiper-button-next-m")
        .click();
      console.log(789);
    },

    handleSelectTpye(val) {
      this.sel = val;
      if (val == 0) {
        this.ids = "1704670299637411842";
      } else {
        this.ids = "1704670418562707457";
      }
      this.getNews();
    },
    handleSwitch(idx) {
      this.idx = idx;
    },
    _isMobile() {
      // console.log(123456789);
      let flag =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      console.log(flag, 5555);
      return (this.isMobile = flag);
    },

    handleChange() {},
    handleMouseEnter(val) {
      this.hoverKey = val;
    },
    handleMouseLeave() {
      this.hoverKey = -1;
    },
    onindexs(val) {
      if (val == 1) {
        let id = "5";
        this.$router.push({
          path: `/currency/${id}`,
        });
      } else if (val == 2) {
        let id = "6";
        this.$router.push({
          path: `/Industry/${id}`,
        });
      }
    },
    onindex(val) {
      if (val == 1) {
        this.$router.push("/MES");
      } else if (val == 2) {
        this.$router.push("Download");
      } else if (val == 3) {
        this.$router.push("CRM");
      } else if (val == 4) {
        this.$router.push("ERP");
      }
    },
    getNewsList() {
      let data = {
        categoryId: "5",
        pageSize: 5,
      };
      let data2 = {
        categoryId: "6",
        pageSize: 5,
      };
      Companydynamics(Qs.stringify(data)).then((result) => {
        this.dynamic = result.data.rows;
      });
      Industrydynamics(Qs.stringify(data2)).then((result) => {
        this.Journalism = result.data.rows;
      });
    },
    // 点击新闻详情
    newsdetail(item) {
      let obj = {
        id: item.id,
        contentDisplay: "0",
      };
      Newsdetail(obj).then((res) => {
        let strItem = JSON.stringify(item);
        if (item.categoryId == "5") {
          this.$router.push(
            `/A2/` + encodeURIComponent(strItem).replace(/%/g, "%25")
          );
        }
        if (item.categoryId == "6") {
          this.$router.push(
            `/I1/` + encodeURIComponent(strItem).replace(/%/g, "%25")
          );
        }
      });
    },
    linkTo(name_) {
      this.$router.push({
        name: name_,
      });
    },
  },
};
</script>

<style lang="less">
.defistyle .defiwidth {
  width: 220px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.Mswiper {
  .swiper-pagination-bullets {
    bottom: 50px !important;
    .swiper-pagination-bullet {
      background: whitesmoke;
      margin: 0 3px;
    }
  }
}

.MswiperMobile {
  .swiper-pagination-bullets {
    text-align: center !important;
    bottom: 50px !important;
    .swiper-pagination-bullet {
      background: #1484ff !important;
    }
  }
}
body {
  // height: 220vh;
  overflow-x: hidden;
}

.pc-soft-provide {
  padding: 50px 0 20px 0;
  margin-top: 1rem;
  font-family: "思源黑体";
  .pro-tit {
    // font-size: 36px;
    color: #333;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .items {
    display: flex;
    align-items: center;
    img {
      width: 10rem;
      height: 10rem;
    }
    .datas {
      color: #1484ff;
      // font-size:50px;
      font-weight: 700;
    }
    .tip {
      color: #333333;
      font-size: 1rem;
    }
  }
}

.mobile-soft-provide {
  padding: 50px 0 0 0;
  font-family: "思源黑体";

  height: 550px;
  // border: 1px solid red;
  .container {
    // border: 1px solid black;
    height: 100%;
    margin: 10px auto;
    width: 95%;
    background-image: url("../assets//newSite//banner/s4.png");
    height: 550px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    margin-top: -100px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
  }
  .row {
    border-radius: 5px;
  }
  .pro-tit {
    color: #333;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 700;
  }
  .pro-tits {
    color: #666666;
    font-size: 13px;
    // margin: 10px 0 50px 0;
  }
  .wrap {
    // background: rgba(220, 237, 255, 1);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
  .items {
    display: flex;
    align-items: center;
    padding-inline: 0.5rem;
    background: white;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    img {
      width: 8rem;
      height: 8rem;
    }
    .datas {
      color: #1484ff;
      font-weight: 700;
    }
    .tip {
      color: #333333;
      font-size: 1rem;
    }
  }
}

.pc-product {
  .acticv {
    border-bottom: 2px solid rgba(20, 132, 255, 1);
  }
  .col {
    padding: 1rem 0;
    cursor: pointer;
  }
  .pro-wrap {
    background: rgba(245, 246, 247, 1);
    text-align: left;
    .container {
      display: flex;

      .content {
        display: flex;

        padding: 2rem;
        background: white;
        margin: 40px 0;
        width: 100%;
        img {
          width: 20rem;
          height: 20rem;
        }

        .p-content {
          color: #666;
          font-size: 0.8rem;
          font-family: "思源黑体";
          line-height: 1.6rem;
        }
        .btns {
          margin: 1rem 0;
          button {
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}

.mobile-product {
  .acticv {
    border-bottom: 2px solid rgba(20, 132, 255, 1);
  }
  .col {
    padding: 1rem 0;
    cursor: pointer;
  }
  .pro-tit {
    // margin-top: 1.5rem;
  }
  .pro-wrap {
    background: rgba(245, 246, 247, 1);
    text-align: left;
    .container {
      display: flex;
      .content {
        padding: 1.5rem;
        background: white;
        margin: 1rem 0;
        width: 100%;
        img {
          width: 20rem;
          height: 20rem;
        }
        .p-content {
          color: #666;
          font-size: 0.8rem;
          font-family: "思源黑体";
          line-height: 1.6rem;
        }
        .btns {
          margin: 1rem 0;
          button {
            margin-right: 0.8rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.pc-resolve {
  padding: 50px 0 20px 0;
  margin-top: 1rem;
  font-family: "思源黑体";
  .pro-tit {
    // font-size: 36px;
    color: #333;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .bg1 {
    height: 23rem;
    background: url("../assets/newSite/bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: end;
    text-align: left;
    .main-content {
      width: 35rem;
    }
  }
  .bg2 {
    height: 23rem;
    background: url("../assets/newSite/home/a2.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: start;
    text-align: left;
    .main-content {
      width: 35rem;
    }
  }
  .bg3 {
    height: 23rem;
    background: url("../assets/newSite/home/a1.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: end;
    text-align: left;
    .main-content {
      width: 35rem;
    }
  }
  .row {
    display: flex;
    align-items: center;
    padding: 3rem;
    p {
      font-size: 1rem;
      color: #666666;
      font-family: "思源黑体";
      margin-top: 1.5rem;
    }
  }
}

.mobile-resolve {
  padding: 50px 0 20px 0;
  margin-top: 1rem;
  font-family: "思源黑体";
  .pro-tit {
    // font-size: 36px;
    color: #333;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .bg1 {
    height: 12.5rem;
    background: url("../assets/newSite/home/b.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: end;
    text-align: right;
    .main-content {
      width: 35rem;
    }
  }
  .bg2 {
    height: 12.5rem;
    background: url("../assets/newSite/home/c.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: start;
    text-align: left;
    .main-content {
      width: 35rem;
    }
  }
  .bg3 {
    height: 12.5rem;
    background: url("../assets/newSite/bs.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: end;
    text-align: right;
    .main-content {
      width: 35rem;
    }
  }
  .row {
    display: flex;
    align-items: center;
    padding: 2rem;
    p {
      font-size: 1rem;
      color: #666666;
      font-family: "思源黑体";
      margin-top: 1rem;
    }
  }
}

.pc-news {
  padding: 30px 0 60px 0;
  margin-top: 1rem;
  font-family: "思源黑体";
  background: #f5f6f7;
  .pro-tit {
    color: #333;
    margin-bottom: 20px;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }

  .news-swiper {
    display: flex;
    .controls {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .opertaion {
        height: 70px;
        line-height: 70px;
        cursor: pointer;
        background: white;
        font-size: 1rem;
        font-family: "思源黑体";
        position: relative;
      }
      .active {
        background: rgba(224, 239, 255, 1);
        color: rgba(20, 132, 255, 1);
        position: relative;
        overflow: hidden;
        :after {
          position: absolute;
          content: "";
          left: 0;
          height: 70px;
          width: 3px;
          background: rgba(20, 132, 255, 1);
        }
      }
      .preAndNext {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .before,
        .after {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #ccc;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
        }
        .dis {
          cursor: not-allowed !important;
        }
      }
    }
    .swiper {
      width: 1000px;
      margin-left: 50px;
      .swiper-slide {
        background: white;
        &:hover {
          cursor: pointer;
        }
      }
      .bgArror {
        width: 30px;
        height: 10px;
        background-image: url("../assets/newSite/line.png");
        background-size: contain;
        margin-left: 10px;
        margin-top: 20px;
      }
      .swiper-pagination {
        display: none;
      }
      img {
        width: 100%;
        height: 150px;
        overflow: hidden;
      }
    }
    .swiper-container {
      height: 320px;
    }
  }

  .swiper-container .swiper-button-next {
    display: none;
  }

  .swiper-container .swiper-button-prev {
    display: none;
  }
}

.mobile-news {
  padding: 30px 0 20px 0;
  margin-top: 1rem;
  font-family: "思源黑体";
  background: #f5f6f7;
  .pro-tit {
    color: #333;
    margin-bottom: 20px;
  }
  .pro-tits {
    color: #666666;
    font-size: 1rem;
    margin: 20px 0 50px 0;
  }
  .controls {
    .wrap {
      display: flex;
    }
    .opertaion {
      width: 100%;
      height: 70px;
      line-height: 70px;
      cursor: pointer;
      background: white;
      font-size: 1rem;
      font-family: "思源黑体";
      position: relative;
    }
    .active {
      background: rgba(224, 239, 255, 1);
      color: rgba(20, 132, 255, 1);
      position: relative;
      overflow: hidden;
      :after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 3px;
        width: 100%;
        background: rgba(20, 132, 255, 1);
      }
    }
  }

  .news-swiper {
    .m_swiper {
      // width: 900px;
      .swiper-slide {
        border: 1px solid #ccc;
      }
      img {
        width: 100%;
        height: 200px;
        overflow: hidden;
      }
    }
    .swiper-container {
      height: 300px;
    }

    .preAndNext {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;
      .before,
      .after {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #ccc;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
      .activ {
        border: 1px solid rgba(20, 132, 255, 1);
      }
      .dis {
        cursor: not-allowed !important;
      }
    }
  }

  .swiper-container .swiper-button-next {
    display: none;
  }

  .swiper-container .swiper-button-prev {
    display: none;
  }
}

.css_time {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.title {
  height: 48px;
  margin: 0 auto;
  font-size: 34px;
  letter-spacing: 3px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 600;
  text-align: center;
  color: #333333;
  line-height: 10px;
}
.TitleContent4 {
  font-size: 1.8vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 18%;
  top: 36%;
}
.TitleContent2 {
  font-size: 3vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 23%;
  top: 21%;
}

.Webanapp {
  font-size: 3vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 72%;
  top: 21%;
}
.Efficientmanagement {
  font-size: 1.8vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 18%;
  top: 36%;
}
.Efficientmanagement1 {
  font-size: 3vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 71%;
  top: 21%;
  z-index: 9;
}

.Efficientmanagement4 {
  font-size: 3vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 20.6%;
  top: 21%;
}
.css_button2 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 23%;
  top: 65%;
  border-radius: 25px;
  height: 48px;
  width: 144px;
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.css_button3 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 71%;
  top: 65%;
  border-radius: 25px;
  height: 48px;
  width: 144px;
  background-color: #1584ff;
  color: white;
  cursor: pointer;
  z-index: 99;
}
.css_button4 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 71%;
  top: 65%;
  border-radius: 25px;
  height: 48px;
  width: 144px;
  background-color: #1584ff;
  color: white;
  cursor: pointer;
  z-index: 99;
}
.css_button5 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 22%;
  top: 65%;
  border-radius: 25px;
  height: 48px;
  width: 144px;
  background-color: #1584ff;
  color: white;
  cursor: pointer;
}
.Weibanhelpyou {
  font-size: 1.8vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 66%;
  top: 36%;
}
.TitleContent {
  position: absolute;
  left: 41%;
  top: 50%;
  font-size: 2vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #262c33;
}
.el-carousel__item--card {
  width: 34%;
}
.el-carousel__container {
  margin-left: 110px;
}
.TitleContent3 {
  font-size: 1.8vw;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #ffffff;
  line-height: 96px;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 66%;
  top: 36%;
  z-index: 9;
}
.css_button {
  font-size: 24px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 46%;
  top: 65%;
  border-radius: 25px;
  width: 164px;
  height: 66px;
  /* border: 1px solid red; */
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.css_button1 {
  font-size: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 46%;
  top: 65%;
  border-radius: 25px;
  width: 144px;
  height: 48px;
  /* border: 1px solid red; */
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.css_homepage {
  position: relative;
}
.Clickthelink {
  display: flex;
  // border: 1px solid red;
  flex-direction: column;
  justify-content: space-between;
  // z-index: 9999;
  // height: 80px;
  // box-shadow: 10px 10px 5px #9521de;
}
.Clickthelink1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  /* bottom: 10%; */
  top: 50%;
  font-size: 1vw;
  color: white;
}

.css_shenglue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 500px;
  // border: 1px solid red;
}
.Clickthelinkcont {
  display: flex;
  justify-content: inherit;
  padding: 5px 20px;
  cursor: pointer; //鼠标悬浮变 "手";
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 620px;
  margin: 16px 0px;
}
.Clickthelinkcont1 {
  display: flex;
  // border: 1px solid red;
  justify-content: inherit;
  padding: 0px 0px;
  // box-shadow: 10px 10px 5px #9521de;
  cursor: pointer//鼠标悬浮变 手;
}
// .right_time{
//       margin-left: 223px;
// }
.css_imgcont2_title {
  position: absolute;
  top: 7%;
  left: 37%;
  display: flex;
}
.dynamic {
  color: white;
  font-size: 30px;
  margin-left: 10px;
  padding-top: 3px;
}
.Newsinformation {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 60px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.css_Picturecontainer {
  position: relative;
  display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // margin: 40px 0px;
}
.css_imgcont {
  display: flex;
  width: 49.4%;
  height: 18%;
  margin: 5px;
  overflow: hidden;
}
.css_imgcont2 {
  cursor: pointer;
  flex-direction: column;
  display: flex;
  width: 100%;
  // height: 18%;
  margin: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 18px 0px #daebff;
}
.showImg {
  width: 100%;
  height: 100%;
  transition: transform 2s;
}
.showImg:hover {
  transform: scale(1.2, 1.2);
}

.describe {
  // width: 1240px;
  // height: 84px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #666666;
  line-height: 24px;
  margin: 0px;
}
// @import "../styles/public";
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
}
.ellipsis1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
  margin-left: 22px;
}
.ellipsis2 {
  margin-right: 250px;
  font-size: 18px;
  margin-left: 22px;
}
.customer {
  text-align: left;
  margin: 20px 36px 31px 40px;
}
.img {
  padding-top: 10px;
}
.customer_img {
  margin-left: -17px;
}
.el-carousel__item:nth-child(2n) {
  background-color: white;
  height: 325px;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: white;
}
.customer_name {
  width: 50%;
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #333333;
  margin: 10px 0 20px;
}
.customer_introduce {
  width: 100%;
  height: 81px;
  opacity: 1;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #858d9a;
  line-height: 24px;
}

 
</style>
